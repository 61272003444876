<template>
  <div style="height: inherit">
    <b-overlay :show="showLoading" spinner-variant="primary">
      <b-modal hide-footer ref="item-modal" title="Détails de l'article" size="xl" scrollable>
        <ProductDetailsPARA :product="selected" />
      </b-modal>

      <b-modal centered size="sm" id="modal-prevent-closing" ref="add-to-cart-modal" :title="product.description"
        hide-footer>
        <b-form-input placeholder="La quantité demandée" ref="qte-input" v-model="requestedQte" type="number"
          @keyup.enter="handleOk"></b-form-input>
        <div class="d-block text-center">
          <b-button variant="relief-success" class="mt-1" @click="handleOk">Valider</b-button>
        </div>
      </b-modal>

      <!-- ECommerce Header -->
      <section id="ecommerce-header">
        <div class="row">
          <div class="col-sm-12">
            <div class="ecommerce-header-items">
              <div class="result-toggler">
                <feather-icon icon="MenuIcon" class="d-block d-lg-none" size="28"
                  @click="mqShallShowLeftSidebar = true" />
                <div class="search-results">
                  {{ totalProducts }} articles trouvés
                </div>
              </div>
              <div class="view-options d-flex">
                <!-- Sort Button -->
                <b-dropdown :text="sortBy.text" right variant="outline-primary">
                  <b-dropdown-item v-for="sortOption in sortByOptions" :key="sortOption.value"
                    @click="sortBy = sortOption">
                    {{ sortOption.text }}
                  </b-dropdown-item>
                </b-dropdown>

                <!-- Item View Radio Button Group  -->
                <b-form-radio-group v-model="itemView" class="ml-1 list item-view-radio-group" buttons size="sm"
                  button-variant="outline-primary">
                  <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                    <feather-icon :icon="option.icon" size="18" />
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Searchbar -->
      <div class="ecommerce-searchbar mt-1">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input v-model="filters.q" placeholder="Search Product" class="search-product"
                @input="updateSearchQuery" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- Products -->
      <section :class="itemView">
        <b-card v-for="(product, index) in products" :key="index" class="ecommerce-card" no-body>
          <div class="item-img text-center product-content">
            <b-link>
              <div @click="showDetails(product)">
                <div class="position-absolute top-0 end-0 p-1" :key="productKey"
                  v-if="product.promotion != undefined && product.promotion.includes('-')">
                  <b-badge pill variant="info" class="badge-glow">{{ product.promotion }}</b-badge>
                </div>
                <b-img v-if="product.defImage == undefined || product.defImage == null || product.defImage == ''"
                  :alt="`${product.description}-${product.code}`" fluid class="card-img-top product-img"
                  :src="require('@/assets/images/default.jpg')" />
                <b-img v-else :alt="`${product.description}-${product.code}`" fluid class="card-img-top product-img"
                  :src="$serverpath + product.defImage" />
              </div>
            </b-link>
          </div>
          <!-- Product Details -->
          <b-card-body @click="showDetails(product)">
            <div class="item-wrapper">
              <div class="item-rating" :key="productKey">
                <code v-if="product.itemStatus == undefined" class="shimmer-line"
                  style="color : #7367f0;animation: shimmer 10s linear infinite;padding-right: 130px;"></code>
                <b-badge v-else pill :variant="product.itemVariant" class="badge-glow">{{ product.itemStatus
                  }}</b-badge>
              </div>
              <div>
                <h6 class="item-price" :key="productKey">
                  <code v-if="product.price == undefined" class="shimmer-line"
                    style="color : #7367f0;animation: shimmer 10s linear infinite;padding-right: 100px;"></code>
                  <b-badge v-else pill variant="light-primary" class="badge-glow">{{ formatPrice(product.price) }}
                    TND</b-badge>
                </h6>
              </div>
            </div>
            <h6 class="item-name">
              <b-link class="text-body">
                {{ product.description }}
              </b-link>
              <b-card-text class="item-company">
                By <b-link class="ml-25">
                  {{ product.vendorDescription }}
                </b-link>
              </b-card-text>
            </h6>
            <b-card-text class="item-description">
              {{ product.familyDescription }} - {{ product.subFamilyDescription }}
            </b-card-text>
          </b-card-body>

          <!-- Product Actions -->
          <div class="item-options text-center">
            <div class="item-wrapper">
              <div class="item-cost">
                <h4 class="item-price" :key="productKey">
                  <code v-if="product.price == undefined" class="shimmer-line"
                    style="color : #7367f0;animation: shimmer 10s linear infinite;padding-right: 150px;"></code>
                  <b-badge v-else pill variant="light-primary" class="badge-glow">{{ formatPrice(product.price) }}
                    TND</b-badge>
                </h4>
              </div>
            </div>
            <b-button variant="light" class="btn-wishlist" :key="wishListKey" @click="toggleWishlist(product)">
              <feather-icon icon="HeartIcon" class="text-warning mr-50"
                :class="{ 'fill-current': product.isInWishlist }" />
              <span>{{ product.isInWishlist ? 'Retirer des Favoris' : 'Ajouter aux Favoris' }}</span>
            </b-button>

            <b-button variant="primary" class="btn-cart" @click="handleCartActionClick(product)">
              <feather-icon icon="ShoppingCartIcon" class="mr-50" />
              <span>Commander</span>
            </b-button>
          </div>
        </b-card>
      </section>

      <!-- Pagination -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination v-model="currentPgae" :total-rows="totalProducts" :per-page="perPage" first-number
              align="center" last-number prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>

      <!-- Sidebar -->
      <portal to="content-renderer-sidebar-detached-left">
        <shop-left-filter-sidebar :filters="filters" :filter-options="filterOptions" :showLoading="showLoading"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" @triggerFilter="handleEventFilter" />
      </portal>
    </b-overlay>
  </div>
</template>

<script>
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './CatalogShopLeftFilterSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatPrice } from "@/utils/formatting";
import ProductDetailsPARA from "@/views/product/ProductDetailsPARA.vue";

export default {
  components: {
    ShopLeftFilterSidebar,
    ProductDetailsPARA
  },
  data() {
    return {
      wishListKey: 0,
      selected: {},
      product: {
        Description: ""
      },
      formatPrice: formatPrice,
      requestedQte: '',
      uri: 'item',
      productKey: 1,
      currentPgae: 1,
      perPage: 9,
      debounceTimeout: null,
      showLoading: true,
      allProductsWithoutFilters: [],
      allProducts: [],
      products: [],
      totalProducts: 0,
      sortBy: { text: 'Trier Par', value: 'updatedAt-DESC' },
      sortByOptions: [
        { text: 'Date Modification Asc', value: 'updatedAt-ASC' },
        { text: 'Date Modification Desc', value: 'updatedAt-DESC' },
        // { text: 'Prix Asc', value: 'priceTTC-ASC' },
        // { text: 'Prix Desc', value: 'priceTTC-DESC' },
        { text: 'Description A-Z', value: 'description-ASC' },
        { text: 'Description Z-A', value: 'description-DESC' },
      ],
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      filters: {
        q: '',
        priceRangeDefined: 'Tous',
        categories: 'Tous',
        vendors: 'Tous',
        ratings: 1,
        pages: "Arrivages",
        subCategory: "Tous",
        companyId: 1
      },
      filterOptions: {
        priceRangeDefined: [
          { text: 'Tous', value: 'Tous' },
          { text: '<= 10 TND', value: '<=10' },
          { text: '10 TND - 30 TND', value: '10-30' },
          { text: '30 TND - 50 TND', value: '30-50' },
          { text: '>= 50 TND', value: '>=50' },
        ],
        categories: [],
        subCategories: [],
        pages: [
          "Arrivages",
          "Nouveautés",
          "Promotions",
          "Répartitions"
        ],
        vendors: [],
        ratings: [
          { rating: 1, value: 1 },
          { rating: 3, value: 3 },
          { rating: 4, value: 4 },
        ],
      }
    }
  },
  watch: {
    tableData(products) {
      if (products.length > 0) {
        this.setCategories();
        this.setVendors();
      }
    },
    currentPgae() {
      this.loadProducts();
    },
    async sortBy() {
      this.currentPgae = 1;
      this.loadProducts();
    }
  },
  setup() {
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const defaultImage = require('@/assets/images/default.jpg');
    return {
      mqShallShowLeftSidebar,
      defaultImage,
    }
  },
  methods: {
    async showDetails(data) {
      let element = this.$store.getters["app-items/geItemPARAByCode"](data.code);
      this.selected = element;
      this.$refs['item-modal'].show();
    },
    handleOk() {
      this.$refs['add-to-cart-modal'].hide();
      this.showLoading = true;
      this.product["Qte"] = this.requestedQte;
      this.$store.dispatch('app-items/GET_INVENTORY', this.product).then((response) => {
        this.showLoading = false;
        this.$toast.clear();
        this.$toast({
          component: ToastificationContent,
          props: response,
        }, { timeout: 2000 });
      }).catch((error) => {
        this.$toast.clear();
        if (error.title == "Article Actuellement Indisponible") {
          this.$toast({
            component: ToastificationContent,
            props: error,
          }, { position: 'top-left' });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: error,
          });
        }
        this.showLoading = false;
      });
    },
    handleCartActionClick(data) {
      this.product = data;
      this.requestedQte = "";
      this.$refs['add-to-cart-modal'].show();
      setTimeout(() => {
        this.$refs["qte-input"].focus();
      }, 400);
    },
    updateSearchQuery() {

      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        if (this.filters.q.trim() != "") {
          this.currentPgae = 1;
          this.loadProducts();
        }
      }, 1500);

    },
    async handleEventFilter() {
      this.mqShallShowLeftSidebar = false;
      this.filters.q = "";
      this.currentPgae = 1;
      this.loadProducts();
    },
    async loadProducts() {
      this.showLoading = true;
      try {
        const [sortBy, sortDir] = this.sortBy.value.split('-');
        const response = await this.$http.get('_item/withPagination', {
          params: {
            companyId: this.filters.companyId,
            vendor: this.filters.vendors,
            subFamilyDescription: this.filters.subCategory,
            search: this.filters.q,
            page: this.currentPgae - 1,
            size: this.perPage,
            sortBy : sortBy,
            sortDir : sortDir
          }
        });
        // console.log(response);
        this.products = response.data.content;
        this.totalProducts = response.data.totalElements;
        this.showLoading = false;
        this.loadItemCompanyInfo();
      } catch (error) {
        console.error('Error loading products:', error);
        this.showLoading = false;
      }
    },
    setCategories() {
      try {
        // Create an object to store unique categories by familyDescription
        const categoriesBySubfamily = this.$store.getters["app-items/geItemsPARA"].filter(item => item.familyDescription != null && item.subFamilyDescription != null && item.vendorDescription != null).reduce((acc, product) => {
          const familyDescription = product.familyDescription || "Tous"; // Default to "Tous" if familyDescription is falsy
          if (!acc[familyDescription]) {
            acc[familyDescription] = new Set(); // Use a Set to ensure uniqueness
          }
          if (product.subFamilyDescription) {
            acc[familyDescription].add(product.subFamilyDescription); // Add categories to the Set
          }
          return acc;
        }, {});

        // Convert the object into an array of objects with distinct categories
        const categoriesArray = Object.entries(categoriesBySubfamily).map(([familyDescription, categoriesSet]) => {
          const sortedCategories = Array.from(categoriesSet).sort((a, b) => a.localeCompare(b));
          return { familyDescription: familyDescription, Categories: sortedCategories };
        });

        // Sort the categoriesArray based on familyDescription
        categoriesArray.sort((a, b) => a.familyDescription.localeCompare(b.familyDescription));

        this.filterOptions.categories = categoriesArray;
      } catch (error) {
        console.error('Error retrieving categories:', error);
      }
    },
    setVendors() {
      try {
        // Get unique vendor descriptions
        const distinctVendoros = [...new Set(this.$store.getters["app-items/geItemsPARA"].filter(item => item.familyDescription != null && item.subFamilyDescription != null && item.vendorDescription != null).map(product => product.vendorDescription))];
        // Sort the vendor descriptions alphabetically
        distinctVendoros.sort((a, b) => a.localeCompare(b));
        // Add "Tous" to the beginning of the array
        distinctVendoros.unshift("Tous");
        // Update the filter options with the sorted vendor descriptions
        this.filterOptions.vendors = distinctVendoros;
      } catch (error) {
        console.error('Error retrieving vendors:', error);
      }
    },
    loadItemCompanyInfo() {
      this.products.forEach(async (element) => {
        let fav = await this.$store.dispatch('app-favorites/FIND_FAVORITE', element.code);
        element.isInWishlist = fav != undefined;
        element.imageLoading = true;
        element.price = element.priceTTC;
        element.itemStatus = element.inventory <= 0 ? "Rupture de stock" : "Disponible";
        element.itemVariant = element.inventory <= 0 ? "warning" : "success";
        element.promotion = "PROMO " + element.promotion;
        this.productKey += 1;
      });
    },
  },
  computed: {
    tableData() {
      return this.$store.getters["app-items/geItemsPARA"];
    },
  },
  async created() {
    this.filters.companyId = localStorage.getItem('company');
    if (this.$store.getters["app-items/geItemsPARA"].length > 0) {
      this.$store.dispatch('app-items/FETCH_ITEMS_FROM_BACKEND').catch(() => this.$router.push({ name: 'error' }));
    }
    this.loadProducts();
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.product-img {
  width: 350px;
  height: 250px;
  object-fit: cover;
}

.product-content {
  justify-content: center;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>

<style>
.shimmer-line {
  height: 20px;
  background: linear-gradient(to right, #f3f3f3 8%, #e5e5e5 18%, #f3f3f3 33%);
  background-size: 800px 104px;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
